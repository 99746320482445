import has from 'lodash/has'
import isObject from 'lodash/isObject'
import keys from 'lodash/keys'
import merge from 'lodash/merge'
import unset from 'lodash/unset'

const simplifyGQLResult = (o) => {
  const objectKeys = keys(o)
  if (objectKeys.length === 1 && objectKeys[0] === 'data') {
    o = o.data
  }
  if (has(o, 'attributes')) {
    o = merge(o, o.attributes)
    unset(o, 'attributes')
  }
  for (const key of keys(o)) {
    if (isObject(o[key])) {
      o[key] = simplifyGQLResult(o[key])
    }
  }

  return o
}

export { simplifyGQLResult }
