import {
  BackgroundImage,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  GatsbyImage,
  Link,
  Text
} from '@stars-ecom/shared-atoms-ui'
import { ProductCrossSell, WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import dayjs from 'dayjs'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import React, { useContext } from 'react'

import { simplifyGQLResult } from '../utils/strapi'

const NotFoundPage = (props) => {
  const { pageContext, data } = props
  const { tvShow, website } = pageContext

  const page404 = simplifyGQLResult(get(data, 'strapi.page404S.data.0'))
  const websiteContext = useContext(WebsiteContext)

  const breadCrumbData = [
    { label: 'Accueil', link: '/' },
    { label: `Erreur`, link: '#' }
  ]
  return (
    <Flex direction="column" align="center" w="100%">
      <Flex maxWidth="1000px" className="breadcrumb-wrapper" w="100%" margin="auto">
        <Breadcrumb spacing="4px" separator="/" fontFamily="PT Sans" className="breadcrumb">
          {breadCrumbData.map((d, i) => (
            <BreadcrumbItem key={`breadcrumb_${i}`} isCurrentPage={i === size(breadCrumbData) - 1}>
              <BreadcrumbLink
                href={i === size(breadCrumbData) - 1 ? undefined : d?.link}
                isCurrentPage={i === size(breadCrumbData) - 1}>
                {d?.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Flex>
      <Flex
        direction="column"
        width="100%"
        justify="center"
        align="center"
        backgroundColor="#f6f3f1">
        <BackgroundImage
          backgroundImage={page404?.backgroundImage?.image}
          style={{
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            textAlign: 'center',
            padding: 0,
            transition: 'all .4s',
            height: '100%',
            width: '100%',
            zIndex: 1
          }}>
          <Flex
            minHeight={{ base: '0', md: '521px' }}
            flexDirection={{ base: 'column', md: 'row' }}
            maxWidth="1000px"
            width="100%"
            margin="0 auto"
            p={{ base: '55px 14px 0', md: '55px 0 0' }}
            justify="space-between">
            <Flex direction="column" width={{ base: '100%', md: '45%' }}>
              <Text
                as="h1"
                textAlign="left"
                fontSize={{ base: '28px', md: '60px' }}
                lineHeight={{ base: '40px', md: '60px' }}
                fontWeight={700}
                fontFamily={{
                  base: 'PT Sans, Arial, sans-serif',
                  md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
                }}>
                {page404?.title}
              </Text>
              <Text
                mt="30px"
                mb="30px"
                textAlign="left"
                fontFamily={{
                  base: 'PT Sans, Arial, sans-serif',
                  md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
                }}
                fontSize={{ base: '14px', md: '18px' }}
                lineHeight={{ base: '20px', md: '27px' }}>
                {page404?.content}
              </Text>
              <Link to="/">
                <Flex
                  width="175px"
                  height="35px"
                  backgroundColor={{ md: websiteContext?.mainColor }}
                  _hover={{ backgroundColor: websiteContext?.lightColor }}
                  color={{ base: websiteContext?.mainColor, md: '#fff' }}
                  justify={{ md: 'center' }}
                  align="center"
                  cursor="pointer"
                  fontFamily={{
                    base: 'PT Sans, Arial, sans-serif',
                    md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
                  }}
                  fontSize={{ base: '14px', md: '19px' }}
                  fontWeight={400}>
                  {page404?.buttonText}
                </Flex>
              </Link>
            </Flex>
            <Flex direction="row" justify="flex-end" width={{ base: '100%', md: '50%' }}>
              {page404?.simpleImage && (
                <GatsbyImage
                  image={page404?.simpleImage?.image?.childImageSharp?.gatsbyImageData}
                  alt={page404?.title}
                />
              )}
            </Flex>
          </Flex>
        </BackgroundImage>
        {!isEmpty(tvShow) && !isEmpty(tvShow.products) && (
          <Flex justify="center" align="center" backgroundColor="#eef4ff" width="100%">
            <ProductCrossSell
              container="Page 404"
              products={tvShow.products}
              title1="Les produits de l'émission"
              title2={
                tvShow?.type == 'PER_DAY'
                  ? `du ${dayjs(tvShow?.code, 'YYYY-MM-DD').format('DD MMMM YYYY')}`
                  : website?.title
              }
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export const query = graphql`
  query page404S($websiteId: ID) {
    strapi {
      page404S(filters: { websites: { id: { eq: $websiteId } } }) {
        data {
          id
          attributes {
            title
            content
            buttonText
            simpleImage {
              data {
                id
                attributes {
                  mime
                  url
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            backgroundImage {
              data {
                id
                attributes {
                  mime
                  url
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

NotFoundPage.pageType = '404'

export default NotFoundPage
